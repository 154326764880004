Vue.component('classificationFilterComponent', {
    props: ['filterField'],
    data:function() {
        return {
            display:true,
            renderView:false,
            collections:[],
        };
    },
    computed:{
        getCollections(){
            return this.collections;
        },
    },
    watch: {
        '$route': 'generateFilter',
        '$store.state.itemTimeFlag':'generateFilter',
        '$store.state.itemUpdate': 'generateFilter'
    },
    methods:{
        orderCollections(collections){
            if(collections.length>0) {
                let self = this;
                function sortCollectionName(a, b) {
                    console.log(a, b)
                    let aname = a.label;
                    let bname = b.label;
                    if (self.filterField.ItemField) {
                        aname = self.$store.getters.tr(a.label);
                        bname = self.$store.getters.tr(b.label);
                    }
                    return (aname > bname ? 1 : ((bname > aname) ? -1 : 0))
                }
                //console.log(collections.sort(sortCollectionName));
                this.collections =collections.sort(sortCollectionName);
            }
        },
        async loadCategory(categoryCode) {
            let url = `/ecommerce/api/getCategoryFilter`;
            let resp = await axios.get(url,{
                    params: {
                        'categoryCode': categoryCode
                    }
                });
            let classificationList = ArrayToMap(resp.data.categoryList,'Code');
            return classificationList;
        },
        async generateFilter(){
            let collection = new Map();
            let items = this.$store.getters.getItemsVisibles;
            //let items = this.$store.state.items;
            let classificationApply = this.filterField.FilterCollection;
            let classificationList = await this.loadCategory(classificationApply);
            //console.log('Category Load',classificationList);
            for(let item of items) {
                let itemsClassification = item.Classification;
                if(!itemsClassification)
                    continue;
                let classOfItem = itemsClassification.split(',');
                //console.log(classOfItem);
                for(let itemClass of classOfItem){
                    if(!itemClass)
                        continue;
                    let valueSplit = classificationList.get(itemClass);

                    if(!valueSplit) {
                        continue;
                    }

                    if(!collection.has(valueSplit.name)) {
                        let objectFilter = {};
                        objectFilter.name = `classification-filter-${valueSplit.name}-${valueSplit.code}`;
                        objectFilter.type = 'classificationFilter';
                        objectFilter.label = valueSplit.name;
                        objectFilter.code = valueSplit.code;
                        objectFilter.filterField = "Classification";
                        objectFilter.itemCount = 1;
                        objectFilter.hiddens=new Map();
                        objectFilter.condition = `x.Classification.split(',').indexOf(\`${valueSplit.code}\`)!=-1`;
                        objectFilter.result = true;
                        objectFilter.active= false;
                        objectFilter.global= false;
                        collection.set(valueSplit.name,objectFilter);
                    }
                }
            }

            this.orderCollections(Array.from(collection.values())) ;
            this.calculateResults();
        },
        resetCountFilters:function(){
            for(let filter of this.collections) {
                filter.itemCount = 0;
            }
        },
        calculateResults: function(){
            let testLabel = 'Calcula Classification Filter Result '+uuidv4();
            //console.group(testLabel)
            let items = this.$store.getters.getItemsVisibles; //this.$store.getters.getItems
            this.resetCountFilters();
            let global = 0;
            for(let x of items.filter((e)=>e.visible)) {
                //console.log('Test Counter -1',x,this.collections);
                for (let interval of this.collections) {
                    /*let cond = interval.condition;
                    let result = interval.result;*/
                    let isPresent = RegExp(interval.code, 'gi');
                    let isVisible = isPresent.test(x[interval.filterField]);
                    //console.log('Test Counter 2',isPresent,x,isVisible);
                    if (isVisible) {
                        interval.itemCount = interval.itemCount + 1;
                        interval.active = this.$store.state.filters.indexOf(interval.name)!==-1;
                        global = global + 1
                    }
                }
            }
            this.renderView = global>0;
        },
        launchFilter:function(filters){
            filters.active= true & filters.active;
            this.$store.dispatch('addFilter',filters);
            let cat = this.$store.getters.getCategoryDataByCode(filters.code);
            this.$store.dispatch('updateCategory',cat);
        },
        getItemValue:function(item,fieldName){
            if(item.fields)
                item=item.fields;
            return item[fieldName]
        },
        getCategoryName:function(categoryCode){
            try {
                let catTree = this.$store.getters.getCategoryDataByCode(categoryCode);
                if(catTree) {
                    //console.log(catTree)
                    return catTree.name;
                }
                return null;
            }
            catch(err){
                //console.log('Error in categoryFilters',categoryCode,catTree,err);
                return categoryCode;
            }
        },
        fetchData() {
            this.renderView=!this.renderView;
            this.calculateResults();
            //console.log('After Update Items',this.collections);
        },
    },
    mounted:function(){
        this.generateFilter();
    },
    template: `<div class="filter" v-if="renderView">
                   <div>
                        <h5 class="custom-title">
                            {{tr(filterField.ItemField)}}
                            <span v-show="display" @click="display = !display" class="right"><i class="icon fa fa-minus"></i></span>
                            <span v-show="!display" @click="display = !display" class="right"><i class="icon fa fa-plus"></i></span>
                        </h5>
                    </div>
                    <section class="filter-options price-range">
                        <ul>
                          <template v-for="filterClass of getCollections">
                                <li :key="'li'+filterClass.name" v-if="filterClass.itemCount>0">
                                  <input :key="'input'+filterClass.name" type="checkbox" v-model="filterClass.active" :id="filterClass.name" :name="filterClass.name"  @click="launchFilter(filterClass)"/>
                                  <template v-if="filterField.FilterMustTranslated">
                                    <label :for="filterClass.name" style="max-width: 90%;">{{tr(filterClass.label)}}</label>
                                  </template>
                                  <template v-else>
                                    <label :for="filterClass.name" style="max-width: 90%;">{{filterClass.label}}</label>        
                                  </template>
                                  <label :for="filterClass.name" class="right">{{filterClass.itemCount}}</label>
                                </li>
                          </template>
                        </ul>
                    </section>
               </div>`,
});
